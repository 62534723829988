<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <span v-else>
      <h5 class="text-center text-primary">View Student Evaluation</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 1: Select a student</strong>
            <div class="text-center" v-if="loadStudents">
              <b-spinner label="Loading..." variant="warning"></b-spinner>
            </div>
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Type to filter data"
            ></b-form-input>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
            <b-table hover selectable
              :select-mode="selectMode"
              @filtered="onFiltered"
              :filter="filter"
              :per-page="perPage"
              :current-page="currentPage"
              :items="listAllStudents"
              :fields="fieldsStudent"
              @row-clicked="onClickStudent">
              <template v-slot:cell(label)="{ item }">
                <b-avatar variant="info" :src="item.picture" class="mr-3"></b-avatar>{{item.label}}
              </template>
            </b-table>
          </b-col>
          <b-col>
            <div class="mt-4" />
            <strong class="text-primary">Step 2: View details</strong>
            <div class="text-center" v-if="loadDetails">
              <b-spinner label="Loading..." variant="warning"></b-spinner>
            </div>
            <b-table
              v-else
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
            ></b-table>

            <div>
              Sorting By: <b>{{ sortBy }}</b>, Sort Direction:
              <b>{{ sortDesc ? 'descending' : 'ascending' }}</b>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </span>
    <div class="mt-3" />
  </div>
</template>

<script>
  import StudentService from '@/services/StudentService.js';
  import EvaluationService from '@/services/EvaluationService.js';

  export default {
    name: 'attendancestudent',
    title: "TPA Gembira - attendance student",
    data() {
      return {
        loading: false,
        loadDetails: false,
        loadStudents: false,
        listAllStudents: [],
        user_id: null,
        sortBy: 'date',
        sortDesc: false,
        fields: [
          { key: 'date', sortable: true },
          { key: 'content', sortable: true },
          { key: 'notes', sortable: true },
          { key: 'teacher', sortable: true }
        ],
        items: [],
        selectMode: 'single',
        perPage: 5,
        currentPage: 1,
        rows: 1,
        filter: null,
        fieldsStudent: [
          { key: 'label', sortable: true, },
        ],
      }
    },
    created(){
      this.init();
    },
    methods: {
      onClickStudent(item){
        if (this.user_id !== item.user_id){
          this.loadDetails = true;
          this.user_id = item.user_id; 
          this.items = [];
          EvaluationService.getEvaluation(this.user_id, null, null, null)
            .then(evaluation => {
              evaluation.forEach((item) => {
                this.items.push({ 
                  'date' : item.date, 
                  'content': item.content, 
                  'notes': item.remarks, 
                  'teacher' : this.generateNameLabel(item.teacher.given_name, item.teacher.family_name, item.teacher.nickname, item.teacher.email)                
                });
              });
              this.loadDetails = false;
            });
        }
      },
      async init(){
        this.loading = true;
        await Promise.all ([ this.getAllStudents()])
          .then(() => {
            this.loading = false;
          });
      },
      async getAllStudents(){
        this.loadStudents = true;
        StudentService.getListStudents()
          .then(
            (list => {
              this.$set(this, "listAllStudents", this.generateNameLabels(list));
              this.rows = this.listAllStudents.length
              this.loadStudents = false;
            })
          );
      },
      onFiltered(filteredItems) {
        this.rows = filteredItems.length
        this.currentPage = 1
      },
    }
  } 
</script>

<style lang="sass" scoped>
</style>