import axios from "axios"
import { getInstance } from "../auth/index";

export default {
  async getListStudents(){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let res = await axios.get("https://tpagembira.azurewebsites.net/student", { headers: {authorization: `Bearer ${accessToken}`}});
    // let res = await axios.get("http://localhost:3000/student", { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  },
}