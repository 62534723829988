import axios from "axios"
import { getInstance } from "../auth/index";

export default {
  async getEvaluation(user_id, date, teacher_id, limit, isDescending = false){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let queryStr = teacher_id !== null || limit !== null ? "?" : "";
    queryStr +=  teacher_id !== null ? "teacher_id=" + teacher_id : "";
    if (limit !== null){
      if (queryStr.length > 0){
        queryStr += "&limit=" + limit;
      } else {
        queryStr += "limit=" + limit;
      }
    }

    if ((isDescending !== null) && (isDescending)){
      if (queryStr.length > 0){
        queryStr += "&sort=desc";
      } else {
        queryStr += "sort=desc";
      }
    }
    let res = await axios.get("https://tpagembira.azurewebsites.net/studentevaluation/" + user_id 
    // let res = await axios.get("http://localhost:3000/studentevaluation/" + user_id
      + ((date !== null) ? "/" + date : "") 
      + queryStr, 
      { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  },
  async upsertEvaluation(user_id, data){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let options = {
      method: 'POST',
      // url: "http://localhost:3000/studentevaluation/" + user_id,
      url: "https://tpagembira.azurewebsites.net/studentevaluation/" + user_id,
      headers: {
        'content-type': 'application/json',
        'authorization': 'Bearer ' + accessToken
      },
      data: data
    }

    axios.request(options)
      .catch(function (error) {
        console.error(error);
      })
    return "OK";
  },
  async deleteEvaluation(userid, date, teacher_id){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let options = {
      method: 'DELETE',
      // url: "http://localhost:3000/studentevaluation/" + userid
      url: "https://tpagembira.azurewebsites.net/studentevaluation/" + userid
        + ((date !== null) ? "/" + date : "") 
        + ((teacher_id !== null) ? "?teacher_id=" + teacher_id : ""), 

      headers: {
        'content-type': 'application/json',
        'authorization': 'Bearer ' + accessToken
      }
    }
    return axios.request(options)
      .catch(function (error) {
        console.error(error);
      })
  },

}